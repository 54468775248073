<template>
  <div>
    <XDataTable
        ref="xDataTableRefactoring"
        class="test-group-view"
        :title="groupName"
        :headers="TABLE_HEADERS"
        :items-request="getGroupTestStatus"
        :items-request-params="itemsRequestParams"
        :ready-to-load="Boolean(groupId)"
        :select-actions="selectActions"
        :refresh="15"
        :custom-range="customRange"
        @refresh="() => refreshStatusesCounts()"
        @sorting-change="(header) => updateSortingData(header.value)"
        no-count
    >
      <template #search-input-slot>
        <XTextField
            class="test-group-view__search-text-field"
            :value="search"
            clearable
            label="Search"
            append-icon="mdi-magnify"
            @input="(searchStr) => handleSearchUpdate(searchStr)"
        />
      </template>

      <template #above-table>
        <div class="test-group-view__above-table">
          <div class="test-group-view__status-bar">
            <XBtn
                class="test-group-view__total-button"
                :text="statusTotal.text"
                height="25"
                :color="statusesFilter.length ? `${statusTotal.color} lighten-3` : `${statusTotal.color}`"
                elevation="0"
                @click="() => selectTotalStatuses()"
            />

            <SplitButton
                class="test-group-view__statuses-split-btn"
                v-model="statusesFilter"
                :values="statusesCounts"
                :splits="statusesRepr"
                :total="statusesCounts?.total || 0"
                multiple
            />
          </div>
        </div>
      </template>

      <template #[`item.testInfo`]="{ row }">
        <TestInfoButton
            :test-id="row.id"
            :test-id-key="shaKey(row.id)"
        />
      </template>

      <template #[`item.testCase.name`]="{ value, row }">
        <template v-if="(typeof row.currentStep.id!='undefined' &&row.currentStep.id> 0) ||
                         (typeof row.endTime!='undefined' && row.endTime!= 0) ">
          <SearchRouterLink
              v-model="search"
              :text="value"
              :to="getTestInfoPath(row.id)"
          />
        </template>
        <template v-else>
          <div style="cursor: pointer;" title="The testcase results is being prepared, so please be patient">
            {{ row.testCase.name }}
          </div>
        </template>
      </template>

      <template #[`item.status`]="{ value, row }">
        <div class="test-group-view__group-test-status">
          <v-icon :color="testStatus.find(x => x.id === value).color">
            {{ testStatus.find(x => x.id === value).icon }}
          </v-icon>

          <span>
            {{ row.currentStep.id }}
          </span>

          <span v-if="row.currentStep.name">
            <template v-if="shortProtocolsMapping[row.currentStep.name]">
              ({{ shortProtocolsMapping[row.currentStep.name] }})
            </template>

            <template v-else>
              ({{ row.currentStep.name }})
            </template>
          </span>
        </div>
      </template>

      <template #[`item.subscriber`]="{ value,row }">
        <SubscriberExplorerLabel
            :type="row.type"
            :value="value"
        />
      </template>
      <template #[`item.owner`]="{ value,row }">
        <v-tooltip top>
          <template #activator="{ attrs, on }">
            <span
                class="test-group-view__owner-first-name"
                v-bind="attrs"
                v-on="on"
            >
              <template v-if="row.type===4">
                API Service
              </template>
              <template v-else>
                 {{ value.firstName }}
              </template>
            </span>
          </template>

          <span>
            {{ `${value.firstName} ${value.lastName}` }}
          </span>
        </v-tooltip>
      </template>

      <template #pagination-slot="{ metadata }">
        <PaginationBlock
            :total-count="metadata.totalCount || 0"
            :current-page="page"
            :items-per-page="metadata.perPage || 0"
            @update:current-page="(pageN) => setPageN(pageN)"
        />
      </template>

      <template #items-per-page-slot>
        <XSelect
            class="test-group-view__items-per-page-select"
            :items="ITEMS_PER_PAGE_OPTIONS_DEFAULT"
            :value="itemsPerPage"
            @input="(_itemsPerPage) => updateItemsPerPage(_itemsPerPage)"
            label="Items per page"
            required
            :autocomplete="false"
        />
      </template>
    </XDataTable>

    <LoadingDialog :value="loading"/>
  </div>
</template>

<script>
import {defineComponent, onBeforeMount, ref, computed, provide} from "vue"
import {useRoute} from "vue-router/composables";
import {mapMutations} from "vuex"
import XDataTable from '@/components/basic/XDataTable.vue';
import PaginationBlock from "@/components/basic/tables/PaginationBlock.vue";
import XSelect from '@/components/basic/XSelect.vue';
import TestInfoButton from '@/components/specific/TestInfo/TestInfoButton.vue';
import SubscriberExplorerLabel from '@/components/specific/SubscriberExplorerLabel.vue';
import {shaKey} from '@/js/helper';
import testStatus from '@/cfg/testStatus.json';
import shortProtocolsMapping from '@/cfg/shortProtocolsMapping.json';
import testCaseTypes from '@/cfg/testCaseTypes.json';
import SplitButton from '@/components/basic/SplitButton';
import XBtn from '@/components/basic/XBtn.vue';
import LoadingDialog from '@/components/basic/LoadingDialog.vue';
import SearchRouterLink from '@/components/basic/SearchRouterLink.vue';
import XTextField from '@/components/basic/XTextField.vue';
import cockpitTestStatusService from "@/js/services/CockpitTestStatusService";
import {secondsToDuration2, unixToDateTimeString} from '@/js/general';
import requests from "@/js/requests";
import testCaseInfoService from "@/js/services/TestCaseInfoService";
import {usePaginationWithRouter} from "@/composition/filtering-components/use-pagination-w-router"
import {useStatusBarSelectWithRouter} from "@/composition/filtering-components/use-status-bar-select"
import {useSearchInputWithRouter, MIN_SEARCH_LENGTH} from "@/composition/filtering-components/use-search-input-w-router"
import {useTableSettingsWithRouter, ITEMS_PER_PAGE_OPTIONS_DEFAULT} from "@/composition/tables/use-table-settings"
import {useTableSortingWithRouter} from "@/composition/filtering-components/use-sorting-w-router"
import {debounce} from "lodash-es"

const TABLE_HEADERS = [
  {
    value: 'testInfo',
    width: 50,
  },
  {
    text: 'Test Name',
    value: 'testCase.name',
    sortable: true,
  },
  {
    text: 'Type',
    value: 'type',
    formatter: value => testCaseTypes[value],
    width: 100,
  },
  {
    text: 'Subscriber/Explorer',
    value: 'subscriber',
    width: 200,
    sortable: true,
  },
  {
    text: 'Owner',
    value: 'owner',
    width: 120,
  },
  {
    text: 'Start Time',
    value: 'startTime',
    formatter: unixToDateTimeString,
    width: 142,
    sortable: true,
  },
  {
    text: 'Finish Time',
    value: 'endTime',
    formatter: unixToDateTimeString,
    width: 142,
    sortable: true,
  },
  {
    text: 'Duration',
    value: 'duration',
    formatter: (value, row) => {
      const endTime = row['endTime'] ? row['endTime'] : parseInt(new Date().getTime() / 1000);
      return secondsToDuration2(endTime - row['startTime'], {useColonFormat: true})
    },
    width: 120,
    sortable: true,
  },
  {
    text: 'Status',
    value: 'status',
    width: 150,
    sortable: true,
  },
  {
    text: 'Result Info',
    value: 'resultInfo',
    sortable: true,
  },
  {
    text: 'Audit',
    value: 'audit',
  }
]

const GROUP_ID_PARAM = "groupID"

// Termporary solution
// TODO: remove this function after using new table components
function getGroupTestStatus(
    groupId,
    status,
    search,
    itemsPerPage,
    sortBy,
    descending,
    page,
    params,
    then,
    error
) {
  params["search"] = search
  params["items-per-page"] = itemsPerPage
  params["sortBy"] = sortBy
  params["descending"] = descending
  params["page"] = page
  cockpitTestStatusService.getGroupTestStatus(groupId, status, params, then, error)
}

const TABLE_SETTINGS_KEY = "test-group-view"

export default defineComponent({
  name: 'TestGroupView',

  components: {
    SplitButton,
    SearchRouterLink,
    LoadingDialog,
    XBtn,
    TestInfoButton,
    XDataTable,
    XTextField,
    XSelect,
    PaginationBlock,
    SubscriberExplorerLabel
  },

  setup() {
    const route = useRoute()
    const {
      statusesCounts,
      statusesFilter,
      statusesRepr,
      statusTotal,
      statusesAsParamString,
      selectTotalStatuses,
    } = useStatusBarSelectWithRouter({multiple: true})
    const groupId = ref(0)
    const groupName = ref("")
    const {
      search,
      searchAsReqParam,
    } = useSearchInputWithRouter()
    const xDataTableRefactoring = ref(null)

    const {
      page,
      setPageN
    } = usePaginationWithRouter()

    const {
      settings: tableSettings,
      updateItemsPerPage: updItmsPerPage,
    } = useTableSettingsWithRouter({key: TABLE_SETTINGS_KEY, defaultSettings: {itemsPerPage: 25}})
    const itemsPerPageFromUrl = ref(route.query.itemsPerPage ? parseInt(`${route.query.itemsPerPage}`) : null)
    /**
     * @param {number} numberOfItems
     */
    const updateItemsPerPage = (numberOfItems) => {
      updItmsPerPage(numberOfItems)
      if (itemsPerPageFromUrl.value) {
        itemsPerPageFromUrl.value = null
      }
    }
    const itemsPerPage = computed(() => {
      if (itemsPerPageFromUrl.value) {
        return itemsPerPageFromUrl.value
      }
      return tableSettings.value.itemsPerPage
    })

    const {
      sortingData,
      updateSortingData,
    } = useTableSortingWithRouter()

    const getGroupTestStatusCounts = async () => {
      const params = {
        search: search.value,
      };

      try {
        return await cockpitTestStatusService.getGroupTestStatusCounts(groupId.value, params)
      } catch (err) {
        console.error("[TestGroupView.getGroupTestStatusCounts]", err)
      }
    }

    const refreshStatusesCounts = async () => {
      if (!groupId.value) return

      statusesCounts.value = await getGroupTestStatusCounts()
    }

    const loadData = async () => {
      const id = parseInt(`${route.query[GROUP_ID_PARAM] || 0}`)
      const p = []
      groupId.value = id

      if (groupId.value) {
        p.push(cockpitTestStatusService.getGroupName(id).then((name) => {
          groupName.value = name
        }))
        p.push(refreshStatusesCounts())
      }

      await Promise.all(p)
    }

    onBeforeMount(async () => {
      await loadData()
    })

    provide("x-data-table-refactoring", {
      sortingData,
    })

    const customRange = {
      from: new Date(0),
    }

    return {
      page,
      setPageN,
      xDataTableRefactoring,
      statusesCounts,
      statusesFilter,
      statusesRepr,
      statusTotal,
      statusesAsParamString,
      selectTotalStatuses,
      search,
      searchAsReqParam,
      groupId,
      groupName,
      refreshStatusesCounts,
      loadData,
      testStatus,
      getGroupTestStatus,
      updateItemsPerPage,
      itemsPerPage,
      sortingData,
      updateSortingData,
      customRange,
      shortProtocolsMapping,

      TABLE_HEADERS,
      ITEMS_PER_PAGE_OPTIONS_DEFAULT,
    }
  },

  data() {
    return {
      loading: false,
      selectActions: Object.freeze([
        // {
        //   icon: 'mdi-stop',
        //   text: 'Stop Selected',
        //   iconColor: "red",
        //   click: (selectedItems) => {
        //     selectedItems = Object.keys(selectedItems).filter(key => selectedItems[key]);
        //     testCaseInfoService.stopTests(selectedItems);
        //   },
        // },

        {
          icon: 'mdi-restart',
          text: 'Restart',
          /**
           * @param {number[]} testQueueIds
           */
          click: async (testQueueIds) => {
            const groupId = this.groupId;
            const msgDuration = 3000;
            const msgOptions = {length: msgDuration}

            return testCaseInfoService.startTests({queueIds: testQueueIds, groupId})
                .then(async () => {
                  this.notification('Successfully restarted', msgOptions)
                  // TODO: Remove this hack, once we refactor the table component
                  // it is a bad practice in vue
                  this.xDataTableRefactoring.loadData()
                  await this.refreshStatusesCounts()
                })
                .catch(err => {
                  this.notification(err.message, msgOptions)
                })
          },
        },

        {
          icon: 'mdi-restart',
          text: 'Restart in a new group',
          /**
           * @param {number[]} testQueueIds
           */
          click: (testQueueIds) => {
            const msgDuration = 3500;
            const msgOptions = {length: msgDuration}
            const newQuery = {...this.$route.query}
            return testCaseInfoService.startTests({queueIds: testQueueIds})
                .then(({testGroupId}) => {
                  this.notification("Successfully restarted in the new group, redirecting...", msgOptions)
                  newQuery[GROUP_ID_PARAM] = testGroupId
                  setTimeout(() => {
                    this.$router.push({...this.$route, query: newQuery})
                  }, msgDuration);
                })
                .catch(err => {
                  this.notification(err.message, msgOptions)
                })
          },
        },

        {
          icon: 'mdi-folder-zip',
          text: 'Detailed Report (zip)',
          click: (selectedItems) => {
            this.loading = true;
            requests.frameworkPostRequest('?f=testing&f2=testInfoPdf', 'getZippedReports', {
              responseType: 'ajax',
              selected: selectedItems,
            }, (result) => {
              this.loading = false;
              window.location.href = result.json['redirect'];
            });
          },
        },
      ]),
    };
  },

  watch: {
    '$route.query.groupID': {
      handler() {
        this.loadData();
      }
    }
  },

  computed: {
    itemsRequestParams() {
      return [
        this.groupId,
        this.statusesAsParamString,
        this.searchAsReqParam,
        this.itemsPerPage,
        this.sortingData.sortBy,
        this.sortingData.descending,
        this.page,
      ];
    },
  },

  methods: {
    ...mapMutations(['notification']),

    shaKey,

    handleSearchUpdate: debounce(async function (searchStr) {
      if ((searchStr.length && searchStr.length < MIN_SEARCH_LENGTH) || this.search?.length < MIN_SEARCH_LENGTH && !searchStr.length) {
        this.search = searchStr;
        return
      }

      this.search = searchStr;
      this.setPageN(1);
      await this.refreshStatusesCounts()
    }, 500),

    getTestInfoPath(testQueueId) {
      const testQueueIdKey = shaKey(testQueueId.toString());
      return `/?f=testing&f2=testInfo&function=getInfo&n_id=${testQueueId}&n_id_key=${testQueueIdKey}`;
    },
    isExplorer(type) {
      return type === 8;
    }
  },
});
</script>

<style lang="scss">
.test-group-view {
  &__owner-first-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__above-table {
    display: flex;
    gap: 4px;
  }

  &__total-button {
    flex: 0 0 auto;

    .v-btn:not(.v-btn--round).v-size--default {
      color: var(--v-text-inverted-base);
      font-size: 12px;
    }
  }

  &__status-bar {
    display: flex;
    width: 100%;
    gap: 2px;
    align-items: center;
  }

  &__statuses-split-btn {
    flex: 1;
  }

  &__group-test-status {
    display: flex;
    gap: 3px;
    align-items: center;
  }

  &__search-text-field {
    flex: 1 1 auto;
    max-width: 350px;
  }

  &__items-per-page-select {
    width: 120px;
    flex-shrink: 0;
  }
}
</style>

